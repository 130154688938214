/* This code from https://www.benmarshall.me/responsive-iframes/ */

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 */
  position: relative;
  margin-bottom: 20px;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
