@use "sass:color";
@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

$note-color:       #cfecff;
$note-text-color:  $text-color;
$note-background-color: color.adjust($note-color, $lightness: 5%);
$note-border-width: 2px;
$note-padding:     3px;
$warning-color:    gold;
$error-color:      lightpink;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "aside",
        "base",
        "custom",
        "iframe",
        "layout",
        "media",
        "publication-notes",
        "syntax-highlighting"
;
