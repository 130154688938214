.publication-notes::before {
    background-color: $note-background-color;
    content: "Publication notes";
    display: block;
    font-weight: bolder;
    text-align: center;
    width: 100%;
}

[lang="pl"] .publication-notes::before {
    content: "Notatki opublikowania";
}

.publication-notes {
    border-bottom: solid $note-color 1px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.publication-notes td {
    background-color: $note-background-color;
    padding-left: 5px;
    padding-right: 5px;
}

.publication-notes td:first-child {
    font-weight: bolder;
    text-align: right;
}

.publication-notes ul {
    margin-bottom: 0;
    margin-top: 0;
}
