.post {
  hr {
    border: none;
    border-top: solid gray 1px;
    margin: 20px auto;
  }

  .caption {
    display: block;
    text-align: center;
  }
}

.icon-hacker-news:before, .icon-lobsters:before, .icon-reddit:before {
  display: inline-block;
  font-size: $base-font-size;
  height: $base-line-height * $base-font-size;
  margin-right: 5px;
  text-align: center;
  width: $base-line-height * $base-font-size;
}

.icon-hacker-news:before {
  background-color: #FF6600;
  color: white;
  content: 'Y';
}

.icon-lobsters:before {
  background-color: #ac130d;
  color: white;
  content: 'L';
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
}

.icon-reddit:before {
  content: url(iconmonster-reddit-4.svg);
  /* Couldn't figure out how to center the icon with respect to the text
   * following it, so just added extra margin here to kind-of center it. */
  width: $base-line-height * $base-font-size - 4px;
  margin-left: 2px;
  margin-right: 5px + 2px;
}

table {
  width: 100%;
}

/* See github-comment-link.js for the code that creates these nodes. */
.github-comment-link {
  background-color: $grey-color-light;
  display: none;
  font-size: $base-font-size;
  margin-bottom: $base-line-height;
  padding: 0 0.5em;
  user-select: none; /* So that users don't accidentally select the "Comment" link when copying text. */
}

.github-comment-link::before {
  content: "⧉ ";
}

/* Keep tag list in sync with tag list in github-comment-link.js file. */
:is(p, li, figure):has(> .github-comment-link) {
  position: relative;
}
:is(p, figure):has(> .github-comment-link) {
  // Don't set for `li`, since that needs to retain `display: list-item`.
  display: block;
}

:is(p, li, figure):hover > .github-comment-link {
  background-color: $grey-color-light;
  border: 1px solid $grey-color;
  display: block;
  margin-top: -1 * ($base-line-height * $base-font-size);
  position: absolute;
}

.github-comment-container::before {
  content: "Comment threads";
  display: block;
  font-weight: bold;
}

html:lang("pl") .github-comment-container::before {
  content: "Wątki komentarzy";
}

.github-comment-container::after {
  clear: both;
  content: "";
  display: table;
}

.github-comment-container {
  background-color: $grey-color-light;
  border: 1px solid $grey-color;
  margin: 0.5em 0 0.5em 0em;
  padding: 1em;
}

.github-comment-body {
  max-height: $base-font-size * $base-line-height * 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.github-comment-author {
  font-weight: bold;
}

.github-comment-num-replies {
  background-color: $grey-color-light;
  float: right;
  right: 0;
}

.note {
	background-color: $note-color;
  color: $note-text-color;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	padding: $note-padding;
	text-transform: uppercase;
  display: inline;
  font-stretch: condensed;
  font-size: smaller;
  height: $base-line-height;
  line-height: $base-line-height;
  vertical-align: text-top;

  &.note-warning {
    background-color: $warning-color;
  }

  &.note-error {
    background-color: $error-color;
  }
}

.note-block {
  border: solid $note-color $note-border-width;
  padding: $note-padding;

  &.note-warning {
    border-color: $warning-color;
  }

  &.note-error {
    border-color: $error-color;
  }
}

.note-block {
  margin-bottom: calc(#{$spacing-unit} / 2);
}

.note-block:has(+ .note-block) {
  margin-bottom: -$note-border-width - 1px;
}
