details, figure {
  border: solid $note-color 2px;
  background: $note-background-color;
  display: block;
  margin-bottom: 20px;
  padding: 20px;

  p:last-child {
    margin-bottom: 0;
  }
}

summary {
  color: $note-text-color;
  cursor: pointer;
  font-weight: 700;

  &:before {
    content: 'Aside: ';
  }

  a:after {
    content: '\25BC'; /* arrow pointing down. */
    font-size: 14px;
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
}
